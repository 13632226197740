import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import CoreuiVue from '@coreui/vue'

import QrReader from 'vue3-qr-reader';

const app = createApp(App);

app.use(CoreuiVue);
app.use(store);
app.use(router);
app.use(QrReader);

app.mount('#app');
