<template>
<div class="header-container">
    <div class="left-section">
      <img
        src="../../images/hamburger.svg"
        alt="Hamburger"
        @click="toggleOffcanvas"
        class="header-hamburger"
      >
      <span class="header-text">
        Welcome, <span style="color: #453A94;">Nisha Bhatt</span>
      </span>
    </div>
    <div class="right-section">
      <img
        src="../../images/pro.svg"
        alt="Right Image"
        class="header-right-image"
      >
    </div>

    <COffcanvas
      :backdrop="false"
      placement="start"
      :scroll="false"
      :visible="visibleScrolling"
      @hide="toggleOffcanvas"
      class="custom-offcanvas"
    >
      <COffcanvasBody
        class="offcanvas-body"
        @click="stopPropagation"
      >
        <div class="offcanvas-header">
          <img
            src="../../images/w-tri.svg"
            alt="Hamburger"
            class="offcanvas-hamburger"
            style="height: 21px;"
          >
          <img
            src="../../images/tri-logo.png"
            alt="Some Image"
            class="offcanvas-image"
            style="height: 17px;"
          >
        </div>
        <ul class="offcanvas-menu">
          <li
            v-for="(item, index) in menuItems"
            :key="index"
            :class="{
              active: activeIndex === index,
              'dashboard-item': index === 0,
              'alert-item': index === 1,
              'reports-item': index === 2
            }"
            @click="setActive(index)"
          >
            {{ item }}
          </li>
        </ul>
        <div class="offcanvas-bottom">
          <ul>
            <li
              :class="{ active: activeIndex === menuItems.length }"
              @click="setActive(menuItems.length)"
            >
              <img
                src="../../images/settings copy.svg"
                alt="Settings Icon"
                class="menu-item-icon"
              >
              Settings
            </li>
          </ul>
        </div>
      </COffcanvasBody>
    </COffcanvas>
  </div>

  <div class="dashboard_profile">
    <p class="profile-text">Dashboard</p>
    <!-- <button class="action-button_site-home">
      <img src="../../images/site_plus.svg" alt="Create Site">
      <span>Create Site</span>
    </button> -->
  </div>

  <div class="date_wrapper">
      <div class="system-overview">
        <div class="date-range">
          <div class="date-selector">
            <select v-model="selectedDate" @change="handleDateChange" class="date-select">
              <option value="today">Today</option>
              <option value="tomorrow">Tomorrow</option>
              <option value="month">Month</option>
            </select>
          </div>
          <div class="date-input-wrapper">
            <div class="date-input-container">
              <input 
  type="date" 
  v-model="startDate" 
  class="date-input" 
  ref="startDateInput"
/>
<span class="arrow">→</span>
<input 
  type="date" 
  v-model="endDate" 
  class="date-input" 
  ref="endDateInput"
/>

            </div>
            <img 
              src="../../images/calender.svg" 
              alt="Calendar Icon" 
              class="calendar-icon" 
              @click="openCalendar"            />
          </div>
        </div>
      </div>
    </div>

  <div class="container">
    <div class="card card1">
      <div class="animated_image">
        <img src="../../images/rectangle.png" alt="rectangles">
      </div>
      <div class="top-left">Power Flow</div>
      <div class="top-right">Live</div>
      <div class="bottom">
        <img src="../../images/Solar panel.svg" alt="Image 1">
        <div class="middle-image-container">
          <p class="middle-text">Text</p>
          <img src="../../images/green_arrow.svg" alt="Image 3">
        </div>
        <img src="../../images/Electric tower.svg" alt="Image 2">
      </div>
    </div>

    <div class="card card2">
      <div class="animated_image2">
        <img src="../../images/rectangle.png" alt="rectangles">
      </div>
      <div class="top">Top Text</div>
      <div class="middle">Middle Text</div>
      <div class="bottom">Bottom Text</div>
    </div>
  </div>

  <!-- <div style="padding: 12px;">
    <div class="wifi_container">
      <div class="wifi_heading">Alerts</div>
      <div class="left-wifi-section">
        <div class="wifi" v-for="(image, index) in images" :key="index">
          <img :src="image.src" :alt="image.alt" class="wifi_image">
          <div class="wifi_text">{{ image.text1 }}</div>
          <div class="description_text">{{ image.text2 }}</div>
        </div>
      </div>
      <div class="right-wifi-section">
        <div class="circular-progress-container">
          <span class="left-label">{{ percentage1 }}%</span>
          <svg class="circular-progress" viewBox="0 0 100 100">
            <defs>
              <linearGradient id="progressGradient" x1="0%" y1="0%" x2="0%" y2="0%">
                <stop offset="90%" style="stop-color:#FF9C3E; stop-opacity:2" />
                <stop offset="0%" style="stop-color:#00F2B2; stop-opacity:1" />
              </linearGradient>
            </defs>
            <circle class="circle-bg" cx="50" cy="50" r="45"></circle>
            <circle class="circle-progress" 
                    cx="50" cy="50" r="45"
                    :stroke-dasharray="strokeDasharray"
                    :stroke-dashoffset="circleCircumference - (percentage1 / 100) * circleCircumference">
            </circle>
          </svg>
          <span class="right-label">{{ percentage2 }}%</span>
        </div>
      </div>
    </div>
  </div> -->
  <div style="padding: 12px;" >

    <div class="performance-container">
    <div class="left-side">
      <div class="heading-section">
        <h1>{{ heading }}</h1>
        <div class="dropdown">
          <p class="wifi_drop" >
            <img src="../../images/wifi-drop.jpg" alt="Dropdown Icon" @click="toggleDropdown"/>
          </p>
          <ul :class="{ show: showDropdown }">
            <li @click="updateHeading('Today')">Today</li>
            <li @click="updateHeading('Tomorrow')">Tomorrow</li>
            <li @click="updateHeading('Yesterday')">Yesterday</li>
          </ul>
        </div>
      </div>
      <p class="energy_text">1.65 MWh</p>
      <p class="text">Energy Produced</p>
    </div>
    <div class="right-side">
      <PerformanceChart :height="chartHeight" :width="chartWidth"/>
    </div>
  </div>
  </div>
  <div class="der_container">
    <div class="der_left-side">
      <div class="der_text-top">DER Portfolio</div>
      <div class="der_text-bottom">Updated 48 mins ago</div>
    </div>
    <div class="der_right-side">
      <button class="der_filter-button">
        Filter
        <img src="../../images/filter.jpg" alt="First Image" id="button-image" />
        <img src="../../images/filter_drop.jpg" alt="Dropdown Image" id="button-image" />
      </button>
    </div>
  </div>
  
  <div style="padding: 12px; margin-top: -12px; " >

  <div class="table-container">
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>KWP</th>
          <th>Alerts</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in tableData" :key="index">
          <td>{{ item.name }}</td>
          <td>{{ item.kwp }}</td>
          <td>
            <div
              v-for="(alert, alertIndex) in item.alerts"
              :key="alertIndex"
              class="alert-circle"
              :style="{ backgroundColor: getRandomColor() }"
            >
              {{ alert }}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  </div>
 
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue';
import PerformanceChart from '../components/PerformanceChart.vue';
import Wifi from "../../images/wifi.svg";
import Thunder from "../../images/thunder.jpg";
import Battery from "../../images/battery.jpg";
import { COffcanvas, COffcanvasBody } from '@coreui/vue';


const CIRCLE_RADIUS = 45;
const CIRCLE_CIRCUMFERENCE = 2 * Math.PI * CIRCLE_RADIUS;

export default {
  components: {
    PerformanceChart,
    COffcanvas,
    COffcanvasBody,
  },
  setup() {
    const showDropdown = ref(false);
    const selectedOption = ref('today');
    const heading = ref('Performance Today');
    const percentage1 = ref(30);
    const percentage2 = ref(70);
    const images = ref([
      { src: Wifi, alt: 'Image 1', text1: '6', text2: 'Sites not communicating' },
      { src: Thunder, alt: 'Image 2', text1: '14', text2: 'Sites Alert' },
      { src: Battery, alt: 'Image 3', text1: '8', text2: 'Battery Depleted' }
    ]);

    const visibleScrolling = ref(false);
    const activeIndex = ref(null);
    const menuItems = ref(['Dashboard', 'Alerts', 'Reports']);
    const startDate = ref('');
    const endDate = ref('');
    const selectedDate = ref('today');
    const strokeDasharray = CIRCLE_CIRCUMFERENCE;

    const toggleOffcanvas = () => {
      visibleScrolling.value = !visibleScrolling.value;
    };

    const stopPropagation = (event) => {
      event.stopPropagation();
    };

    const setActive = (index) => {
      activeIndex.value = index;
    };

    const handleDateChange = () => {
      if (selectedDate.value === 'today') {
        startDate.value = new Date().toISOString().slice(0, 10);
        endDate.value = new Date().toISOString().slice(0, 10);
      } else if (selectedDate.value === 'tomorrow') {
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        startDate.value = tomorrow.toISOString().slice(0, 10);
        endDate.value = tomorrow.toISOString().slice(0, 10);
      } else if (selectedDate.value === 'month') {
        const firstDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth(), 1);
        const lastDayOfMonth = new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0);
        startDate.value = firstDayOfMonth.toISOString().slice(0, 10);
        endDate.value = lastDayOfMonth.toISOString().slice(0, 10);
      }
    };

    const openCalendar = () => {
      if (!startDate.value) {
        startDate.value = new Date().toISOString().slice(0, 10);
      }
      if (!endDate.value) {
        endDate.value = new Date().toISOString().slice(0, 10);
      }
    };

    const handleClickOutside = (event) => {
      if (visibleScrolling.value && !event.target.closest('.custom-offcanvas')) {
        visibleScrolling.value = false;
      }
    };

    const toggleDropdown = () => {
      showDropdown.value = !showDropdown.value;
    };

    const updateHeading = (newHeading) => {
      selectedOption.value = newHeading.toLowerCase();
      heading.value = `Performance ${newHeading}`;
      showDropdown.value = false;
    };

    const getRandomColor = () => {
      const colors = ['#FFE8A5', '#FFA63E', '#FF8985', '#13B78C'];
      return colors[Math.floor(Math.random() * colors.length)];
    };

    onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onBeforeUnmount(() => {
      document.removeEventListener('click', handleClickOutside);
    });


    return {
      
      stopPropagation,
      activeIndex,
      menuItems,
      selectedOption,
      toggleOffcanvas,
      visibleScrolling,
      startDate,
      endDate,
      selectedDate,
      handleDateChange,
      openCalendar,
      setActive,
      percentage1,
      percentage2,
      strokeDasharray,
      images,
      heading,
      showDropdown,
      toggleDropdown,
      updateHeading,
      getRandomColor,
      tableData: ref([
        { name: 'Site 1', kwp: '50', alerts: [1] },
        { name: 'Site 2', kwp: '60', alerts: [4] },
        { name: 'Site 3', kwp: '70', alerts: [8] },
        { name: 'Site 4', kwp: '89.10', alerts: [0] },
        { name: 'Site 5', kwp: '75', alerts: [6] },
      ]),
    };
  }
}
</script>










<style scoped>
.header-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  position: relative;
  z-index: 10;
}

.left-section {
  display: flex;
  align-items: center;
}

.header-hamburger,
.header-right-image {
  cursor: pointer;
  width: 24px;
  height: 24px;
  z-index: 10;
}

.header-text {
  margin-left: 20px;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
}

.right-section {
  margin-left: auto;
}

.custom-offcanvas {
  width: 50% !important;
  color: white;
  z-index: 20; 
}

.offcanvas-body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
  width: 55%;
  padding: 0;
  background: linear-gradient(to right, #050011, #120B41);
}

.offcanvas.offcanvas-start {
  border-right: none !important;
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid white;
  padding: 20px 25px;
}

.offcanvas-menu,
.offcanvas-bottom ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.offcanvas-menu {
  padding-top: 20px;
}

.offcanvas-menu li {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  cursor: pointer;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
}

.offcanvas-menu li.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.menu-item-icon {
  margin-right: 10px;
}

.offcanvas-bottom {
  margin-top: auto;
  padding-bottom: 10px;
}

.offcanvas-bottom li {
  display: flex;
  align-items: center;
  padding: 10px 15px;
}

.offcanvas-bottom li.active {
  background-color: rgba(255, 255, 255, 0.2);
}

.offcanvas-bottom li img {
  margin-right: 10px;
}

.offcanvas-bottom li a {
  color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
}

.offcanvas-bottom li a:hover {
  text-decoration: underline;
}

.dashboard_profile {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  padding: 6px 20px; 
  border-bottom: 0.25px solid #AAAAAA;
}

.profile-text {
  font-size: 14px; 
  font-family: 'Montserrat';
  color: #453A94;
  margin: 0; 
}

.action-button_site-home {
  display: flex;
  position: relative;
  flex-direction: row;
  align-items: center;
  gap: 5px;
  background-color: #453A94;
  color: #FFFFFF;
  padding: 8px 20px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
}

.action-button_site-home img {
  margin-right: 5px; 
  width: 20px; 
  height: 20px; 
}

.action-button_site-home span {
  font-size: 12px; 
  font-family: 'Montserrat';
  font-weight: 600;
}

.system-overview {
  margin-top: 20px;
  padding: 15px; 
}
.system-overview h1{
  font-size: 14px;
  font-family: 'Montserrat';
  text-align: left;
  color: #453A94;


}

.date-range {
  display: flex;
  align-items: center;
  gap: 10px;
}

.date-selector {
  margin-right: 10px;
}

.date-select {
  padding: 3px;
  font-size: 12px;
  color: #000000;
  height: 32px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  
}

.date-input-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.date-input-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.date-input {
  width: 90px;
  height: 32px;
  font-size: 12px;
  padding: 0 8px;
  box-sizing: border-box;
  text-align: center;
  line-height: 32px;
  color: #000000;
  background-color: transparent;
}

.arrow {
  font-size: 18px;
}

.calendar-icon {
  margin-right: 5px;
}
.container {
            display: flex;
            gap: 10px;
        }
        .card {
          
            width: 100%;
            background: white;
            border-radius: 20px;
            background: linear-gradient(to right, #050011, #120B41);

            box-shadow: 0 0 10px rgba(0,0,0,0.1);
            overflow: hidden;
            display: flex;
            flex-direction: column;
            padding: 10px;
            box-sizing: border-box;
        }
        .card1 {
  position: relative;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.animated_image{
  position:absolute;
  top: -5%;
  left:15%;
  bottom:39px;
    z-index:-7;

}
.animated_image img{
  height: 100%;

}

.card1 .top-left, .card1 .top-right {
  position: absolute;
  top: 20px; 
  color: #ffffff;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}

.card1 .top-left {
  left: 20px; 
}

.card1 .top-right {
  right: 20px; 
  color:#A0A0A0;
}

.card1 .bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 18px;
  padding: 20px; 
  margin-top: 40px; 
}

.card1 .bottom img {
  height: auto;
}

.card1 .middle-image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.card1 .middle-text {
  text-align: center;
  margin-bottom: -6px; 
  font-size: 12px;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
}


.card2 {
  position: relative;
  z-index: 9999999;
  display: flex;
  flex-direction: column;
  align-items: start;
  background-color: #333;
}

.animated_image2 {
  position: absolute;
  top: -5%;
  left: 15%;
  bottom: 39px;
  z-index: -7;
}

.animated_image2 img {
  height: 100%;
}

.card2 .top, .card2 .middle, .card2 .bottom {
  position: relative;
  top:8%;
  left:6%;
  color: #ffffff;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}
.card2 .middle{
  top:20%;
}


.card2 .bottom {
  margin-top:50px;
}
.wifi_container {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-radius: 20px;
  background-color: #F5F4FF;
  position: relative; 
}

.wifi_heading {
  position: absolute;
  top: 10px;
  left: 20px;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  color: #453A94;
  text-transform: uppercase;
}

.left-wifi-section {
  display: flex;
  flex-direction: column;
}

.wifi {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 10px;
}

.wifi_text {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 800;
}

.description_text {
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;
}

.right-wifi-section {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.circular-progress-container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.circular-progress {
  width: 60px;
  height: 60px;
}

.circle-bg {
  fill: none;
  stroke: #e6e6e6; 
  stroke-width: 10;
}

.circle-progress {
  fill: none;
  stroke-width: 10;
  stroke-linecap: round;
  stroke: url(#progressGradient); 
}

.left-label,
.right-label {
  position: absolute;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  color: #493c3c;
}

.left-label {
  left: -20%; 
  transform: translateX(-100%);
}

.right-label {
  right: -20%; 
  transform: translateX(100%);
}
.performance-container {
  display: flex;
  justify-content: space-around;
  align-items: center; 
  padding: 13px;
  border-radius: 20px;
  background-color: #F5F4FF;
}

.left-side {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.heading-section {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.heading-section h1 {
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-weight: 600;
  margin: 0;
  margin-right: 5px;
  text-transform: uppercase;
  white-space: nowrap; 
}

.dropdown {
  position: relative;
  top:6px;
}

.dropdown ul {
  display: none; 
  position: absolute;
  top: 48%; 
  left: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  padding:0px; 
  list-style: none;
  margin: 0;
  z-index: 1000;
}

.dropdown ul.show {
  display: block;
}

.dropdown ul li {
  padding: 5px 10px; 
  cursor: pointer;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  color: #000;
  text-align: left;
}

.dropdown ul li:hover {
  background-color: #e6e6e6;
}

.text {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  color: #000;
  margin: 5px 0;
  text-align:left;
}

.energy_text {
  font-size: 14px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #000;
  margin: 5px 0;
}

.right-side {
  display: flex;
  align-items: center;
  justify-content: center;
}
.der_container {
  display: flex;
  justify-content: space-between;
  align-items: start;
  padding: 20px; 
}
.der_text-top{
  text-align: left;
  font-size: 14px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}
.der_text-bottom{
  text-align: left;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  color:#AAAAAA;
}

.der_left-side {
  display: flex;
  flex-direction: column;
  gap: 10px; 
}

.der_right-side {
  display: flex;
  align-items: center;
}

.der_filter-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px; 
  color: #656565; 
  border: 0.3px solid #656565;
  padding: 5px 7px;
  cursor: pointer;
  border-radius: 7px; 
}
#button-image{
  margin-bottom: 0px !important;
  
}
.table-container {
  width: 100%;
  overflow-x: auto;
  border-radius: 10px;
  border: 1px solid #ddd; 
}

table {
  width: 100%;
  border-collapse: collapse;
  border-radius: 10px;
}

th, td {
  border: none; 
  padding: 8px;
}

th {
  background-color: #f2f2f2;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif;
  padding:15px;
}


td {
  border-top: 1px solid #ddd;
  font-size: 14px;
  font-weight: 500;
  font-family: 'Montserrat', sans-serif; 
  color:#656565;
}

.alert-circle {
  display: inline-block;
  width: 25px;
  height: 25px;
  line-height: 25px;
  border-radius: 50%;
  text-align: center;
  color: white;
  margin-right: 5px;
  font-size: 15px;
  font-weight: bold;
  font-family: 'Montserrat', sans-serif;
  color:#000000;
}
</style>


