<template>
  <div class="page-container">
    <div class="left-side">
      <div class="back-button-container">
        <img src="../../images/white_back.svg" alt="" class="back-button" @click="goBack" />
      </div>
      <p class="settings-text" v-if="!isReportView">Settings</p>
      <p class="report-text" v-if="isReportView">Report</p>
    </div>

    <img src="../../images/rectangle.png" alt="Top Right Image" class="top-right-image" />

    <div class="upper-div" v-if="!isReportView">
      <div class="circular-div">
        <span class="circular-text">{{ userNameInitial }}</span>
      </div>
      
      <p class="center-text">{{ user ? user.name :'App User' }}</p>

      <div class="label-container">
        <label for="phone">PHONE NUMBER</label>
        <div class="entered">
          <p style="margin: 0;">{{PhoneNumber ? PhoneNumber :'N/A'}}</p>
        </div>

        <label for="email">EMAIL</label>
        <div class="entered">
          <p style="margin: 0;">N/A</p>
        </div>

        <label for="device">DEVICE</label>
        <div class="entered">
          <p style="margin: 0;">TDR2G16EC0208</p>
        </div>

        <label for="device">Inverter</label>
        <div class="entered">
          <p style="margin: 0;">LUMINOUS (NXI305)</p>
        </div>

        <label for="device">IMEI</label>
        <div class="entered">
          <p style="margin: 0;">861850060225915</p>
        </div>

        <label for="device">SIM</label>
        <div class="entered">
          <p style="margin: 0;">8991922305933531881U</p>
        </div>

        <label for="device">LOCATION</label>
        <div class="entered">
          <p style="margin: 0;">Plot no. B- 47 NAJaipur TARA NAGAR B, JHOTWARA, 302012, RJ, JAIPUR</p>
        </div>
      </div>

<!--      <div class="report-section">-->
<!--        <p class="report-text report_settings">Report</p>-->
<!--        <button class="report-button">-->
<!--          <img src="../../images/right_arrow.svg" alt="Right Arrow" class="arrow-icon" />-->
<!--        </button>-->
<!--      </div>-->
      
<!--      <div class="report-section">-->
<!--        <p class="report-text report_password">PASSWORD</p>-->
<!--        <button class="report-button">-->
<!--          <img src="../../images/right_arrow.svg" alt="Right Arrow" class="arrow-icon" />-->
<!--        </button>-->
<!--      </div>-->
      <label for="logout" class="logout entered" @click="logout">LOGOUT</label>
      <button @click="openIframe" class="logout delete">DELETE ACCOUNT</button>
    <DeleteAccount v-if="showIframe" url="https://forms.gle/bK133oAohaau7qev9" @close="showIframe = false" />   
    


      
    </div>

    <div class="upper-div" v-if="isReportView">
      <div class="label-container">
        <label for="report-details">REPORT DETAILS</label>
        <input type="text" id="report-details" class="input-field site_status" placeholder="Site Status" />
      </div>

      <div class="label-container">
        <label for="period">PERIOD</label>
        <div class="period-container">
          <div class="period-text">
            <label for="from">FROM</label>
            <div class="report-date-field-container">
              <input type="date" id="from" class="report-date-field" v-model="fromDate" />
              <img src="../../images/calender.svg" alt="Calendar" class="calendar-icon" />
            </div>
          </div>
          <div class="period-text">
            <label for="to">TO</label>
            <div class="report-date-field-container">
              <input type="date" id="to" class="report-date-field" v-model="toDate" />
              <img src="../../images/calender.svg" alt="Calendar" class="calendar-icon" />
            </div>
          </div>
        </div>
      </div>

      <div class="label-container">
        <label for="output-format">OUTPUT FORMAT</label>
        <select id="output-format" class="dropdown-field">
          <option value="excel">Microsoft Excel</option>
          <option value="pdf">PDF</option>
          <option value="other">Other Format</option>
        </select>
      </div>

      <div class="label-container">
        <label for="language">LANGUAGE</label>
        <select id="language" class="dropdown-field">
          <option value="en">English(US)</option>
          <option value="es">Spanish</option>
          <option value="fr">French</option>
        </select>
      </div>

      <button class="generate-button" @click="generateReport">Generate Report</button>
    </div>
  
  </div>
</template>

<script>
import DeleteAccount from '../components/DeleteAccount.vue'
import PhoneNumber from "@/components/PhoneNumber.vue";
import router from "@/router";
import UserService from "@/services/user.service";
export default {
  name: 'MyPage',
  components: {
    DeleteAccount 
  },
  data() {
    return {
      showIframe: false,

      isReportView: false,
      fromDate: new Date().toISOString().split('T')[0],
      toDate: new Date(new Date().setDate(new Date().getDate() + 7)).toISOString().split('T')[0],
      userName: "App User",
      user:null,
      PhoneNumber:""
    };
  },
  async mounted() {
   this.user=await UserService.getCurrentUser();
   this.PhoneNumber=this.user.phones.shift()?.number;
   console.log(this.PhoneNumber,"logging phonenumber");
   
  },
  computed: {
    userNameInitial() {
      return 'AU';
    }
  },
  methods: {
    openIframe() {
      this.showIframe = true;
    },
    showReportView() {
      this.isReportView = true;
    },
    goBack() {
      this.isReportView = false;
    },
    generateReport() {
    },
    logout(){
      if(localStorage.getItem('_token') && localStorage.getItem('_id')){
         localStorage.removeItem('_token');
         localStorage.removeItem('_id');
        this.$router.push('/login');
      }
    }
  }
};
</script>




<style scoped>
.page-container {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;
  background-color: #f4f4f4;
  /* overflow-y: auto; */
  padding-bottom: 50px;
}

.left-side {
  width: 100%;
  height: 40vh;
  background: linear-gradient(to right, #050011, #120B41);
  padding: 20px;
  border-bottom-right-radius: 25px;
  border-bottom-left-radius: 25px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.back-button-container {
  margin-right: 10px;
}

.back-button {
  width: 20px;
  height: auto;
  cursor: pointer;
}

.settings-text, .report-text {
  font-size: 14px;
  font-weight: bold;
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  text-transform: uppercase;
  margin-top: 1px;
}

.report-text {
  color: #000;
}

.top-right-image {
  position: absolute;
  top: 0;
  right: 0;
  width: 48%;
  height: auto;
}

.upper-div {
  position: absolute;
  top: 16%;
  left: 50%;
  width: 90%;
  transform: translateX(-50%);
  padding: 20px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.circular-div {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 2px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 5px;
  background-color: #453A94;
  position: relative;
  top: -65px;
  box-shadow: 1px 0 20px rgba(0, 0, 0, 0.5);
}

.circular-text {
  font-size: 40px;
  color: #ffffff;
  font-weight: 700;
}

.center-text {
  margin-top: -35px;
  font-size: 20px;
  color: #000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-align: center;
}

.label-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  margin-bottom: 20px;
}

label {
  font-size: 12px;
  color: #000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  margin: 0;
  display: block;
  padding: 0px 0;
}

.input-field {
  display: block;
  width: 100%;
  text-align: left;
  border: none;
  border-bottom: 2px solid #ccc;
  border-radius: 0;
  box-sizing: border-box;
  margin: 0;
  padding: 0px;
  font-size: 14px;
  line-height: 1.5;
}

#report-details {
  border: 1px solid #BBBBBB; 
  border-radius: 8px; 
  padding: 8px; 
  height: 28px;
}

.date-field {
  width: calc(100% - 8px); 
  padding: 8px;
  border: 1px solid grey;
  box-sizing: border-box;
}

.period-container {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.period-text {
  display: flex;
  flex-direction: column;
  gap: 5px;
  flex: 1;


}
.period-text label{
  font-size: 10px;
  font-weight: 500;
}

.dropdown-field {
  width: 100%;
  height: 28px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #ffffff;
  color: #000000;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}

.generate-button {
  width: 100%;
  padding: 12px;
  background-color: #453A94;
  color: #fff;
  border: none;
  border-radius: 7px;
  cursor: pointer;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
}



.report-section {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #ccc;
  margin-top: 14px;
}

.report-text {
  font-size: 14px;
  font-weight: bold;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  margin-right: 10px;
}

.report_settings {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-weight: 600;
}

.report_password {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-weight: 600;
  margin-top: 3px;
}

.report-button {
  margin-top: 5px;
}

.logout {
  margin-top: 10px;
  color:#000000 !important;
}
.delete{
  margin-left: -15px;
  color:red !important;

  font-weight: 700;
}
.report-date-field-container {
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
}


.report-date-input {
  flex: 1; 
  padding-right: 30px; 
  background-color: #ffffff;

}

.report-calendar-icon {
  position: absolute;
  right: 5px;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: auto;
}
.entered{
  width: 100%;
  border-bottom: 2px solid #ccc;
  font-size: 12px;
  color: #949494;
  font-family: 'Montserrat', sans-serif;

  p{
    color: #333;
  }



}
</style>
