<template>
    <div style="padding: 10px;">
      <div class="power_input">
        <p class="power_intro">Total Power Input</p>
        <p class="power_date">30 June, 2024</p>
        <div>
          <BarChart />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import BarChart from "./BarChart.vue";
  
  
  export default {
    components: {
      BarChart
    }
  };
  </script>
  
  <style scoped>
  .power_input {
    padding: 20px;
    background-color: #F9F9F9;
    border-radius: 20px;
    margin-top: 20px;
  }
  
  .power_intro {
    font-size: 18px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    text-align: left;
    margin: 0;
  }
  
  .power_date {
    font-size: 12px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    text-align: left;
  }
  </style>
  