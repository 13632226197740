<template>
    <div style="padding: 20px;">
      <h1>Setup Auto Purchase</h1>
      <p>Add purchase rule</p>
  
      <div class="date-picker-container">
        <input type="date" id="start-date" v-model="startDate" ref="startDateInput" />
        <span class="arrow">→</span>
        <input type="date" id="end-date" v-model="endDate" ref="endDateInput" />
        <img src="../../images/calender.svg" alt="Calendar" @click="openStartDatePicker" class="calendar-icon"/>
      </div>
  
      <div class="card">
        <p class="card-heading">Units on daily basis</p>
        <input type="text" placeholder="Enter value" v-model="inputValue" />
  
        <p class="card-heading">Days of the week:</p>
        <div class="days-of-week">
          <div 
            v-for="day in days" 
            :key="day" 
            :class="['day-circle', { 'selected': selectedDays.includes(day) }]"
            @click="toggleDay(day)"
          >
            {{ day }}
          </div>
        </div>
  
        <p class="card-heading">Maximum Purchase Amount (MPA)</p>
        <input type="text" placeholder="₹" v-model="anotherInputValue" />
        <p class="units">Setting-up 10 units with ₹5/unit MPA on every Monday.</p>

        <button class="add_rule">
            <img src="../../images/grey_plus.svg" alt=""> <span>  Add Rule</span>
          
        </button>
      </div>
  
      <div class="buttons-container">
        <router-link to="/transaction">

        <button class="save-button" @click="saveSettings">Save</button>
        </router-link>
        <button class="cancel-button" @click="cancel">Cancel</button>
      </div>
    </div>
  </template>
  
  <script>
  export default {

    data() {
      return {
        startDate: '',
        endDate: '',
        inputValue: '',
        anotherInputValue: '',
        days: ['M', 'Tu', 'W', 'T', 'F', 'S', 'Su'],
        selectedDays: []
      };
    },
    mounted() {
      this.initializeDates();
    },
    methods: {
      openStartDatePicker() {
        this.$refs.startDateInput.focus();
      },
      openEndDatePicker() {
        this.$refs.endDateInput.focus();
      },
      initializeDates() {
        const randomDate = (start, end) => new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
        const start = new Date();
        const end = new Date();
        end.setFullYear(end.getFullYear());
  
        this.startDate = randomDate(start, end).toISOString().split('T')[0];
        this.endDate = randomDate(start, end).toISOString().split('T')[0];
      },
      toggleDay(day) {
        const index = this.selectedDays.indexOf(day);
        if (index > -1) {
          this.selectedDays.splice(index, 1);
        } else {
          this.selectedDays.push(day);
        }
      },
      saveSettings() {
        console.log('Settings saved');
      },
      cancel() {
        console.log('Settings canceled');
      }
    }
  };
  </script>
  
  <style scoped>
  h1 {
    text-align: left;
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    color: #453A94;
    font-weight: 600;
  }
  p {
    text-align: left;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    font-weight: 600;
  }
  .date-picker-container {
    display: flex;
    align-items: center;
    padding: 10px;
    width: 80%;
    max-width: 500px;
    border-radius: 10px;
    border: 0.5px solid #BDBDBD;
    gap:5px;
  }
  .arrow {
    font-size: 20px;
  }
 
  .card {
    margin-top: 20px;
    padding: 15px;
    background-color: #F3F1FF;
    border-radius: 15px;
  }
  .card-heading {
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    color: #656565;
    margin: 0;
    margin-bottom: 10px;
  }
  .days-of-week {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    font-weight: 700;
  }
  .day-circle {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #ddd;
    cursor: pointer;
  }
  .day-circle.selected {
    background-color:  #453A94;
    color: white;
  }
  .buttons-container {
    display: flex;
    gap: 10px;
    margin-top: 20px;
  }
  .save-button {
    background-color: #453A94;
    color: white;
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
  }
  .cancel-button {
    background-color: #ddd;
    padding: 10px 20px;
    border-radius: 20px;
    border: none;
    cursor: pointer;
  }
  .units {
    font-size: 12px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
  }
  input[type="text"], input[type="date"] {
    margin-bottom: 15px;
    width: 75%;
    padding: 4px;
    border: 0.5px solid #AEAEAE;
    border-radius: 7px;
  }
  input[type="date"] {
    margin-bottom: 0px;
    border: none;
    font-size: 12px;
    max-width: 120px;
  }

  .add_rule{
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #ffffff;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    gap: 12px;
    width: 40%;
    border-radius: 7px;
    padding: 10px 13px;


  }
  </style>
  