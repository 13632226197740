<template>
    <div class="alerts-page">
      <div class="alert_header">
        <button @click="goBack" class="back-button">
          <img src="../../images/back.png" alt="Back" class="back-icon">
        </button>
        <h1 class="title">Summary</h1>
      </div>
  
      <div class="filter-buttons">
        <button 
          @click="filterAlerts('Resolved')" 
          :class="['completed-button', { active: currentFilter === 'Resolved' }]"
        >
          Resolved
        </button>
        <button 
          @click="filterAlerts('Pending')" 
          :class="['pending-button', { active: currentFilter === 'Pending' }]"
        >
          Pending
        </button>
      </div>
  
      <div class="cards">
        <div v-for="alert in filteredAlerts" :key="alert.id" class="alert-card">
          <div class="alert-text-group left">
            <div v-for="(text, index) in alert.leftTexts" :key="index" class="alert-text">
              {{ text }}
            </div>
          </div>
          <div class="alert-text-group right">
            <div v-for="(text, index) in alert.rightTexts.slice(0, -1)" :key="index" class="alert-text">
              {{ text }}
            </div>
            <div :class="['status-container', getStatusContainerClass(alert.status)]">
              <span class="status-text">
                {{ alert.rightTexts.slice(-1)[0] }} 
                <img 
                  :src="getStatusImage(alert.status)" 
                  alt="Status Icon" 
                  class="status-icon"
                >
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  
  <script>
  import { ref, computed } from 'vue';
  import { useRouter } from 'vue-router';
  
  export default {
    name: 'AlertsPage',
    setup() {
      const router = useRouter();
  
      const alerts = ref([
        { id: 1, status: 'Resolved', leftTexts: ['From', 'Type', 'Start Time', 'End Time', 'Status'], rightTexts: ['Owner', 'Vpp cell', '14 Dec 2021 10:50:35', '14 Dec 2021 10:50:36', 'Resolved'] },
        { id: 2, status: 'Pending', leftTexts: ['From', 'Type', 'Start Time', 'End Time', 'Status'], rightTexts: ['Owner', 'Vpp cell', '14 Dec 2021 10:50:35', '14 Dec 2021 10:50:36', 'Pending'] },
        { id: 3, status: 'Resolved', leftTexts: ['From', 'Type', 'Start Time', 'End Time', 'Status'], rightTexts: ['Owner', 'Vpp cell', '14 Dec 2021 10:50:35', '14 Dec 2021 10:50:36', 'Resolved'] },
        { id: 4, status: 'Pending', leftTexts: ['From', 'Type', 'Start Time', 'End Time', 'Status'], rightTexts: ['Owner', 'Vpp cell', '14 Dec 2021 10:50:35', '14 Dec 2021 10:50:36', 'Pending'] },
      ]);
  
      const currentFilter = ref('Resolved');
  
      const filterAlerts = (status) => {
        currentFilter.value = status;
      };
  
      const filteredAlerts = computed(() => {
        return alerts.value.filter(alert => alert.status === currentFilter.value);
      });
  
      const getStatusImage = (status) => {
        return status === 'Resolved'
          ? require('../../images/completed.svg')  
          : require('../../images/pending.svg');   
      };
  
      const getStatusContainerClass = (status) => {
        return status === 'Resolved' ? 'completed-status' : 'pending-status';
      };
  
      const goBack = () => {
        router.back();
      };
  
      return {
        alerts,
        currentFilter,
        filterAlerts,
        filteredAlerts,
        goBack,
        getStatusImage,
        getStatusContainerClass,
      };
    },
  };
  </script>
  
  
  
  
  <style scoped>
  .alerts-page {
    padding: 20px;
  }
  
  .alert_header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .back-button {
    background: none;
    border: none;
    padding: 2px;
    cursor: pointer;
    position: absolute;
    top: 22px;
    left: 20px;
  }
  
  .title {
    margin-left: 15px;
    font-size: 14px;
    font-family: 'Montserrat', sans-serif;
    color: #000000;
    font-weight: 700;
  }
  
  .filter-buttons {
    display: flex;
    margin-bottom: 20px;
  }
  
  .filter-buttons button {
    margin-right: 10px;
    padding: 6px 16px;
    border: none;
    cursor: pointer;
    border-radius: 7px;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
  }
  
  .completed-button {
    background-color: #F4F4F4;
    color: #7F7F7F;
  }
  
  .pending-button {
    background-color: #F4F4F4;
    color: #7F7F7F;
  }
  
  .filter-buttons button.active {
    color: #fff;
  }
  
  .filter-buttons button.active.completed-button {
    background-color: #13B78C;
  }
  
  .filter-buttons button.active.pending-button {
    background-color: #F73737;
  }
  
  .cards {
    display: flex;
    flex-direction: column;
  }
  
  .alert-card {
    display: flex;
    justify-content: space-between;
    padding: 18px 18px;
    border-radius: 20px;
    margin-bottom: 10px;
    background-color: #F4F4F4;
  }
  
  .alert-text-group {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
  
  .alert-text-group.left {
    align-items: flex-start;
    font-size: 12px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    gap: 10px;
  }
  
  .alert-text-group.right {
    align-items: flex-end;
    font-size: 12px;
    color: #000000;
    font-family: 'Montserrat', sans-serif;
    gap: 10px;
  }
  
  .alert-text {
    margin-bottom: 5px;
    font-size: 14px;
  }
  
  .status-container {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 6px 12px;
    border-radius: 7px;
  }
  
  .completed-status {
    background-color: #13B78C;
  }
  
  .pending-status {
    background-color: #F73737;
  }
  
  .status-text {
    font-size: 12px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    color: #fff;
  }
  
  .status-icon {
    width: 13px;
    height: 13px;
  }
  </style>
  
  