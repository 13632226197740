<template>
  <div style="overflow-y: scroll;">
  <div class="home">
    <div class="profile">
      <div class="initials">DR</div>
      <div class="profile-info">
        <div class="profile-text">Hello, User</div>
        <div class="date">{{date}}</div>
      </div>
    </div>
  </div>
  <div class="carousel-container">
    <Carousel
      ref="carousel"
      :itemsToShow="1"
      :itemsToScroll="1"
      :autoplay="false"
      :loop="false"
      v-model="currentSlide"
    >
      <Slide v-for="(slide, index) in slides" :key="index">
        <div class="slide">
          <div
            class="slide-background"
            :style="{ backgroundImage: 'url(' + slide.image + ')' }"
          ></div>
          <div v-if="index === 0" class="slide-content layout1">
            <div class="slide-text"><img src="../../images/tri-new.svg" alt=""></div>
            <div class="slide-text">Centralize, <span>Optimize,</span> Revolutionize</div>
          </div>
          <div v-else-if="index === 1" class="slide-content layout2">
            <div class="left-content">
              <div class="smoke_image">
                <img src="../../images/smoke.svg" class="slide-image-left" />
              </div>
              <div class="slide-text-left">267.29t</div>
            </div>
            <div class="right-content">
              <div class="slide-text-right">Carbon Emission</div>
              <div class="slide-text-right partner">We partner with environmental non-profits and tree- planting organisations globally so you can offset your carbon emissions in your own backyard.</div>
            </div>
          </div>
          <div v-else class="slide-content layout3">
            <div class="left-content">
              <div class="smoke_image leaf">
                <img src="../../images/leaf.svg" class="slide-image-left" />
              </div>
              <div class="slide-text-left">11,678</div>
            </div>
            <div class="right-content">
              <div class="slide-text-right planting">Planting Green <br> Growing Life.</div>
              <div class="slide-text-right partner"></div>
            </div>
          </div>
        </div>
      </Slide>
    </Carousel>
    <div class="dots">
      <span
        class="dot"
        :class="{ active: currentSlide === index }"
        v-for="(slide, index) in slides"
        :key="index"
        @click="goToSlide(index)"
      ></span>
    </div>
  </div>

  <div style="padding: 10px;">
  <div class="power_input">
    <p class="power_intro">Total Power Input</p>
    <p class="power_date">{{tg === 0 ? 'Yesterday' : date}}</p>
    <div>
      <BarChart/>

    </div>
</div>
</div>
  <div style="padding: 10px;">
    <p class="status_top">Status</p>
    <div class="status">
  <div class="status-item">
    <p class="units_today">{{tg.toFixed(2)}} <span>kWh</span></p>
    <p class="today">Today</p>
    <div class="border-line"></div> 
  </div>
  <div class="status-item">
    <p class="units_today">{{yg.toFixed(2)}} <span>kWh</span></p>
    <p class="today">Yesterday</p>
    <div class="border-line"></div> 
  </div>
  <div class="status-item">
    <p class="units_today">{{(atg).toFixed(2)}} <span>KWh</span></p>
    <p class="today">This Month</p>
    <div class="border-line"></div> 
  </div>
</div>
<div class="printing_weather">
  <div class="weather_intro">
    <h2>Your Overall Impact</h2>
    <p>{{date}}</p>
  </div>

  <div class="weather-card-container">
  <div class="weather-card">
    <div class="weather-card-left">
      <div class="weather-image-container" style="background-color: #00278B;">
        <img src="../../images/clouds1.svg" alt="Image 1" />
      </div>
    </div>
    <div class="weather-card-right">
      <p class="weather-main-text">31°</p>
      <p class="weather-sub-text">Weather</p>
    </div>
  </div>
  <div class="weather-card">
    <div class="weather-card-left">
      <div class="weather-image-container" style="background-color: #011CAA;">
        <img src="../../images/solar-weather.svg" alt="Image 4" />
      </div>
    </div>
    <div class="weather-card-right">
      <p class="weather-main-text">{{capacity.toFixed(2)}} <span style="font-size: 10px">kW</span> </p>
      <p class="weather-sub-text">Solar</p>
    </div>
  </div>
  

  
  <div class="weather-card">
    <div class="weather-card-left">
      <div class="weather-image-container" style="  background: linear-gradient(to bottom, #00F2B2, #013426);">
        <img src="../../images/ruppee.svg" alt="Image 3" />
      </div>
    </div>
    <div class="weather-card-right">
      <p class="weather-main-text">{{(ats).toFixed(2)}}</p>
      <p class="weather-sub-text">Savings</p>
    </div>
  </div>
    <div class="weather-card">
    <div class="weather-card-left">
      <div class="weather-image-container" style="background-color: #00F2B2;">
        <img src="../../images/weather-ev.svg" alt="Image 2" />
      </div>
    </div>
    <div class="weather-card-right">
      <p class="weather-main-text">N/A</p>
      <p class="weather-sub-text">EV</p>
    </div>
  </div>
 
</div>


</div>
</div>
</div>
</template>




<script>
import { Carousel, Slide } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css'; 
import BarChart from '../components/BarChart.vue';

import Slide1 from '../../images/slide_bg1.svg';
import Slide2 from '../../images/slide_bg_2.svg'; 
import Slide3 from '../../images/slide_bg_3.svg'; 

import data from '../store/testdata';

export default {
  components: { Carousel, Slide,BarChart},
  data() {
    return {
      slides: [
        { image: Slide1, text1: 'Slide 1 Text 1', text2: 'Slide 1 Text 2' },
        { image: Slide2, text: 'Slide 2 Text' },
        { image: Slide3, text: 'Slide 3 Text' },
      ],
      currentSlide: 0,
      tg: data.todayTotal(),
      yg: data.yesterdayTotal(),
      atg: data.totalGenerationTracked(),
      ats: data.totalSavings(),
      capacity: 6,
      date: data.dateToday()
    };
  },
  methods: {
    goToSlide(index) {
      this.currentSlide = index;
    },
  },
};
</script>
<style scoped>

@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');
.home {
  padding: 0px 20px;
  overflow-y: scroll;
}

.profile {
  display: flex;
  align-items: center;
  margin-top: 18px;
  margin-bottom: 20px;
  gap: 30px;
}

.initials {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #453a94;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  font-weight: bold;
  margin-right: 15px;
}

.profile-info {
  display: flex;
  flex-direction: column;
}

.profile-text {
  font-size: 18px;
  font-weight: bold;
  color: #453a94;
}

.date {
  font-size: 14px;
  color: gray;
}

.carousel-container {
  position: relative;
  width: 100%;
}

.slide {
  position: relative;
  width: calc(100% - 20px); 
  height: 150px;
  border-radius: 20px;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
}

.slide-background {
  position: absolute;
  top: 0;
  right: 0;
  bottom: -16px;
  left: 0;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.slide-content {
  position: relative;
  left: 15px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.layout1 {
  flex-direction: column;
  align-items: start;
}

.layout2 {
  flex-direction: row;
  justify-content: space-between;
  gap: 15px;
  padding: 10px;
}

@media (max-width: 1200px) {
  .layout2 {

    justify-content: flex-start;
  }
}
@media (min-width: 1200px) {
  .layout2 {

    justify-content: flex-start;
  }
}
.layout3 {
  flex-direction: row;
  justify-content: start;
  gap: 20px; 
  padding: 10px;
}

.left-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.right-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.slide-text {
  position: relative;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  padding: 10px;
  z-index: 1;
}

.slide-text span {
  color: #00f2b2;
}

.slide-text-left {
  font-size: 12px;
  padding: 4px 10px;
  z-index: 1;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
}

.slide-text-right {
  font-size: 20px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  padding: 0px 10px;
  z-index: 1;
  text-align: left;
}

.partner {
  font-weight: 500;
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
}

.slide-image {
  margin-top: 10px;
}

.dots {
  display: flex;
  justify-content: center;
}

.dot {
  width: 10px;
  height: 10px;
  background-color: #E4E4E4;
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
}

.dot.active {
  background-color: #00F2B2;
}
.power_input{
  padding: 20px;
  background-color: #F9F9F9;
  border-radius: 20px;
  margin-top: 20px;
}

.power_intro{
  font-size: 18px;
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  text-align: left;
  margin: 0;
}
.power_date{
  font-size: 12px;
  color: #000000;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
}
.smoke_image {
  background-color: #3b5036;
  border-radius: 50%;
  padding: 15px;
}
.leaf{
  padding: 15px 18px;
}
.planting{
  margin-top: -20px;
}
.status {
  display: flex;
  justify-content: space-between;
  padding: 22px 11px;
  background: linear-gradient(to right, #050011, #120B41);
  border-radius: 20px;
}
.status_top{
  font-size: 12px;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  padding: 0px 10px;

}

.status-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  padding: 5px 18px; 
}

.border-line {
  position: absolute;
  width: 1px;
  height: 100%;
  background: #ffffff;
  right: 0;
  top: 0;
  transform: translateX(50%); 
}

.status-item:last-child .border-line {
  display: none; 
}

.units_today {
  font-size: 19px;
  font-family: "Inter", sans-serif;
  color: #ffffff;
  font-weight: 700;
  margin: 0;
}

.units_today span {
  font-size: 10px;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
}

.today {
  font-size: 10px;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;
  margin: 0;
}
.weather_intro{
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  padding: 0px 10px;

}
.weather_intro h2{
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
}
.weather_intro p{
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;


}
.weather-card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px; 

}



.weather-card {
  display: flex;
  width: calc(52% - 20px); 
  background-color: #f0f0f0; 
  border-radius: 10px;
  overflow: hidden;
  color: #ffffff;
  box-shadow: 3px 3px 5px gray; 

}

@media screen and (max-width: 344px) and (max-height: 882px) {
  .weather-card {
    width: 100% !important;

}
}


@media screen and (max-width: 568px) {
  .weather-card {
    width: calc(51% - 16px); 

} 
 }
@media screen and (min-width:768px) {
  .weather-card {
    width: calc(51% - 20px); 

} 
 }


 @media screen and (min-width: 992px) {
  .weather-card {
    width: calc(50% - 11px); 

} 
 }

 @media only screen and (min-width: 600px) and (max-height: 1024px) {
  .weather-card {

  width: calc(50% - 10px); 
  }
}

.weather-card:nth-child(1){
  background-color: #3187D6;
}
.weather-card:nth-child(2){
  background-color: #000000;
}
.weather-card:nth-child(3){
  background-color: #09CA97;
}
.weather-card:nth-child(4){
  background-color: #041A6C;
}

.weather-card-left {
  display: flex;
  align-items: center;
  padding: 12px 15px; 
}

.weather-image-container {
  width: 55px; 
  height: 55px; 
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  overflow: hidden; 
}

.weather-image-container img {
  max-width: 100%;
  max-height: 100%;
}

.weather-card-right {
  flex: 1;
  padding: 12px 0px;
    text-align: left;
    }

.weather-main-text {
  font-size: 20px;
  font-family: "Inter", sans-serif;

  font-weight: bold;
  margin: 0 0 0px;
}

.weather-sub-text {
  font-size: 14px;
  margin: 0;
  color: #666;
  font-family: 'Montserrat', sans-serif;
  color: #ffffff;

}


</style>
