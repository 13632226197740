import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import SplashScreen from "../components/SplashScreen"
import Login from "../views/Login.vue"
import Address from "../views/Address.vue"
import Inverter from "../views/Inverter.vue"
import ScanQr from "../views/ScanQr.vue"
import SuccessfullyAdded from "../views/SuccesfullyAdded"
import HomePage from "../views/HomePage.vue"
// import DetailedConsumption from "../views/DetailedConsumption"
import Dashboard from "../views/Dashboard"
// import SitePage from "../views/SitePage"
import  BuySell from "../views/BuySell"
import AutoPurchase from '../components/AutoPurchase.vue'
import Home from '../views/Home.vue'
import TransactionComplete from "../views/TransactionComplete.vue"
import Summary from "../views/Summary.vue"
import NewPage from '../components/NewPage'

const routes = [
  { path: '/', name: 'splash', component: SplashScreen },

  // {
  //   path: '/',
  //   redirect: '/login' 
  // },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path:'/new_page',
    name:'newPage',
    component:NewPage
  },
  {
    path: '/address',
    name: 'address',
    component: Address
  },
  {
    path:'/connect_to_inverter',
    name:'inverter',
    component:Inverter
  },
  {
    path:'/scanqr',
    name:'scanqr',
    component:ScanQr
  },
  {
    path:'/added',
    name:'successfullyadded',
    component:SuccessfullyAdded
  },
  {
    path:'/homepage',
    name:'homepage',
    component:HomePage
  },
  // {
  //   path:'/detailedconsumption',
  //   name:'detailedconsumption',
  //   component:DetailedConsumption
  // },
  {
    path:'/dashboard',
    name:'dashboard',
    component:Dashboard
  },
  // {
  //   path:'/sitepage',
  //   name:'sitepage',
  //   component:SitePage
  // },
{
  path:'/buysell',
    name:'buysell',
    component:BuySell
},
{
  path:'/autopurchase',
  name:'autopurchase',
  component:AutoPurchase
},
{
  path:'/home',
  name:'home',
  component:Home
},
{
  path: '/summary',
  name: 'summary',
  component: Summary
},
{
  path:'/transaction',
  name:'transaction',
  component:TransactionComplete
},
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
