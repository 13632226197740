<template>
  <div id="homePage">
    <component :is="currentView"></component>

    <nav class="bottom-nav">
      <button @click="changeView('Home')" :class="{'active': currentView === 'Home'}" class="home-button">
        <img src="../../images/ichome.svg" alt="Home">   
        <span>Home</span>
      </button>
      <button @click="changeView('SitePage')" :class="{'active': currentView === 'SitePage'}" class="home-button">
        <img src="../../images/analytics.svg" alt="Site">    
        <span>Site</span>
      </button>
      <button v-if="selectedCard === 'energyTrading'" @click="changeView('BuySell')" class="home-button">
        <img src="../../images/trade_nav.png" alt="Trade">          
        <span>Trade</span>
      </button>
      <button v-if="selectedCard !== 'energyTrading'" @click="changeView('Alerts')" class="home-button">
        <img src="../../images/alerts.svg" alt="Alerts">          
        <span>Alerts</span>
      </button>
      <button @click="changeView('Settings')" :class="{'active': currentView === 'Settings'}" class="home-button">
        <img src="../../images/settings.svg" alt="Settings">
        <span>Settings</span>
      </button>
    </nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import { ref, watch } from 'vue';
import Home from '../views/Home.vue';
import SitePage from './SitePage.vue';
import Alerts from '../views/Alert.vue';
import Settings from '../views/Settings.vue';
import BuySell from '../views/BuySell.vue';

export default {
  components: {
    Home,
    SitePage,
    Alerts,
    Settings,
    BuySell
  },
  setup(props, { emit }) {
    const currentView = ref('Home');
    const showTradeTab = ref(false);

    const changeView = (view) => {
      currentView.value = view;
      emit('updateView', view);
    };

    return {

      currentView,
      showTradeTab,
      changeView
    };
    
  },
  mounted() {
    this.selectedCard = localStorage.getItem('selectedCard');
  },
  computed: {
   
    selectedCard() {
      return this.$store.getters.selectedCard;
    }
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap');

#homePage {
  padding-bottom: 85px; 
}

.bottom-nav {
  display: flex;
  justify-content: space-around;
  padding: 7px 0;
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
  z-index: 99999;
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #fff; 
}

button {
  background: none;
  border: none;
  color: #333;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

button.active {
  font-weight: bold;
  color: #453A94;
}

.home-button img {
  width: 24px;
  height: 24px;
  margin-bottom: 4px;
}

button span {
  font-size: 12px;
  font-family: 'Inter', sans-serif;
  font-weight: 400;
}
</style>