<template>
  <nav>
    <!-- <router-link to="/">Home</router-link> | -->
    <!-- <router-link to="/about">About</router-link> -->
    <!-- <router-link to="/first"></router-link> -->

    <router-link to="/login"></router-link>
    <router-link to="/address"></router-link>
    <router-link to="/connect_to_inverter"></router-link>
    <router-link to="/scanqr"></router-link>
    <router-link to="/added"></router-link>
    <router-link to="/homepage"></router-link>
    <!-- <router-link to="/detailedconsumption"></router-link> -->
    <router-link to="/dashboard"></router-link>

    <!-- <router-link to="/sitepage"></router-link> -->
    <router-link to="/buysell"></router-link>
    <router-link to="/home"></router-link>
    <router-link to="/summary"></router-link>
    <router-link to="new_page"></router-link>


  </nav>
  <div v-if="deferredPrompt" class="install-prompt-backdrop"></div>
    <div v-if="deferredPrompt" class="install-prompt">
      <p><b>WELCOME!</b> To TRILLECTRIC Vision Platform.<br>Install the app and login with your account.</p>
      <button @click="installPWA">Install App</button>
  <!--    <button @click="cancelPWA">Cancel</button>-->
    </div>
  <router-view/>
</template>

<style lang="scss">
@import '@coreui/coreui/dist/css/coreui.min.css';
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;700&display=swap');


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: #000;
  text-align: center;
  color: #2c3e50;

}

nav {

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}

.leaflet-container {
  border-radius: 10px !important;
}

.leaflet-popup-tip {
  display: none !important;
}

.leaflet-container a.leaflet-popup-close-button {
  display: none !important;
}

.leaflet-popup-content-wrapper, .leaflet-popup-tip {
  border: 1px solid #8D7EFD;
}

.leaflet-container .leaflet-marker-pane img {
  margin-top: -105px !important;
}

video {
  margin-top: 10px !important;
}

.overlay-element {
  border-radius: 10px;
  height: 100% !important;
  background: none !important;
}

.install-prompt-backdrop {
  position: fixed;
  top:0;
  left:0;
  right:0;
  bottom:0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 90;
}
.install-prompt {
  position: fixed;
  padding: 10px;
  border-radius: 5px;
  z-index: 100;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  background: white;
  box-shadow: 0px 0px 8px 0px gray;
  top: 30%;
  text-align: center;

  button {
    margin: auto;
    background: lightgreen;
    padding: 4px;
    border: 1px solid green;
    border-radius: 5px;
  }
}
</style>


<script>
  import AuthService from "./services/auth.service";
  import router from "@/router";
export default {
  data() {
    return {
      deferredPrompt: null
    };
  },
  mounted() {
    // Listen for the 'beforeinstallprompt' event
    window.addEventListener('beforeinstallprompt', (e) => {
      // Prevent the mini-infobar from appearing on mobile
      e.preventDefault();
      // Store the event so it can be triggered later
      this.deferredPrompt = e;
      // setTimeout(() => {
      //   this.installPWA();
      // }, 0);
    });
    // if (AuthService.isLoggedIn()) {
    //   router.push("/homepage"); // Redirect to the Home page if already logged in
    // }else{
    //   router.push("/login");
    // }
  },

  methods: {
    installPWA() {
      if (this.deferredPrompt) {
        // Show the install prompt
        this.deferredPrompt.prompt();
        // Wait for the user to respond to the prompt
        this.deferredPrompt.userChoice.then((choiceResult) => {
          if (choiceResult.outcome === 'accepted') {
            console.log('User accepted the install prompt');
          } else {
            console.log('User dismissed the install prompt');
          }
          this.deferredPrompt = null; // Clear the deferred prompt
        });
      }
    }
  }
};
</script>