import ApiCoreService from "./api.core.service";

export default class UserService extends ApiCoreService {
    static get token() {
        const token = localStorage.getItem('_token'); // Fetch from localStorage
        return token ? token : null;
    }
    static get userId() {
        const userId = localStorage.getItem('_id'); // Fetch from localStorage
        return userId ? userId : null;
    }

    static async getCurrentUser() {
        
        try {
            return  await this.graphCall('withAuth.getUser', `{
                withAuth(token: "${this.token}"){
                    getUser(id:"me"){
                      id
                      name
                      gender
                      emails {
                        address     
                      }
                      phones {
                        number                    
                      }
                      status
                      picture{
                        uri 
                      }
                      defaultRole{name}
                    }
                  }
                
            }`);
        } catch (c) {
            throw new Error(c);
        }
    }

}
