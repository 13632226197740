const testdata = [
    [0.0, 0.0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 1.62, 0.92, 0.46, 0.11, 0],
    [0.0, 0.0, 0.63, 1.03, 1.23, 1.97, 2.11, 2.61, 3.43, 3.76, 2.53, 2.00, 1.21, 0.99, 0.40, 0.10, 0],
    [0.0, 0.0, 0.87, 1.31, 1.52, 1.22, 2.73, 2.52, 1.99, 1.72, 1.53, 1.97, 1.61, 0.53, 0.44, 0.15, 0], //18 - dip 1-4pm
    [0.0, 0.0, 0.39, 1.29, 1.62, 1.56, 2.63, 2.76, 2.12, 3.75, 2.56, 2.00, 1.81, 0.87, 0.32, 0.12, 0],
    [0.0, 0.0, 0.21, 1.62, 1.98, 1.82, 2.92, 2.23, 3.97, 2.26, 2.54, 2.01, 1.72, 0.82, 0.09, 0.16, 0], //20
    [0.0, 0.0, 0.43, 1.26, 1.34, 1.41, 2.23, 2.11, 2.11, 3.92, 2.51, 2.37, 1.62, 0.91, 0.32, 0.18, 0],
    [0.0, 0.0, 0.63, 1.03, 1.23, 1.97, 2.11, 2.61, 2.93, 2.86, 2.53, 2.00, 1.21, 0.99, 0.40, 0.10, 0], //22
    [0.0, 0.0, 0.51, 1.11, 1.10, 1.93, 2.30, 2.84, 2.88, 2.78, 2.52, 2.07, 1.68, 0.94, 0.46, 0.11, 0], // 23
    [0.0, 0.0, 0.63, 1.03, 1.29, 1.97, 2.11, 2.61, 3.63, 3.76, 2.53, 2.00, 1.21, 0.99, 0.40, 0.10, 0], //24
    [0.0, 0.0, 0.21, 1.62, 1.98, 1.96, 3.22, 2.23, 3.97, 2.66, 2.54, 2.01, 1.32, 0.82, 0.09, 0.16, 0], //20
    [0.0, 0.0, 0.43, 1.26, 1.34, 1.47, 2.23, 2.11, 2.65, 3.92, 2.51, 2.37, 1.62, 0.91, 0.32, 0.18, 0],
       [0.0, 0.0, 0.21, 1.12, 1.28, 1.36, 2.22, 2.23, 2.97, 2.66, 2.54, 2.01, 1.32, 0.82, 0.09, 0.16, 0], //20
      [0.0, 0.0, 0.4, 0.92, 1.28, 1.36, 2.22, 2.23, 2.87, 1.66, 1.54, 1.51, 1.32, 0.82, 0.09, 0.16, 0], //20
     [0.0, 0.0, 0.4, 0.92, 1.28, 1.36, 2.22, 2.23, 2.87, 1.66, 1.54, 1.51, 1.32, 0.82, 0.09, 0.16, 0], //20
    [0.0, 0.0, 0.4, 0.92, 1.28, 1.36, 2.22, 2.23, 2.87, 1.66, 1.54, 1.51, 1.32, 0.82, 0.09, 0.16, 0], //20
];

const today = 6 + new Date().getDate() - 8;

export default class {
    static loadTodayGraph() {
        return [
            new Date().getHours() >= 5 ? testdata[today][0] : 0,
            new Date().getHours() >= 6 ? testdata[today][1] : 0,
            new Date().getHours() >= 7 ? testdata[today][2] : 0,
            new Date().getHours() >= 8 ? testdata[today][3] : 0,
            new Date().getHours() >= 9 ? testdata[today][4] : 0,
            new Date().getHours() >= 10 ? testdata[today][5] : 0,
            new Date().getHours() >= 11 ? testdata[today][6] : 0,
            new Date().getHours() >= 12 ? testdata[today][7] : 0,
            new Date().getHours() >= 13 ? testdata[today][8] : 0,
            new Date().getHours() >= 14 ? testdata[today][9] : 0,
            new Date().getHours() >= 15 ? testdata[today][10] : 0,
            new Date().getHours() >= 16 ? testdata[today][11] : 0,
            new Date().getHours() >= 17 ? testdata[today][12] : 0,
            new Date().getHours() >= 18 ? testdata[today][13] : 0,
            new Date().getHours() >= 19 ? testdata[today][14] : 0,
            new Date().getHours() >= 20 ? testdata[today][15] : 0,
            new Date().getHours() >= 21 ? testdata[today][16] : 0
        ]
    }

    static loadYesterdayGraph() {
        return testdata[today - 1];
    }

    static todayTotal() {
        return this.loadTodayGraph().reduce((acc, cur) => (acc + cur), 0)
    }

    static yesterdayTotal() {
        return testdata[today - 1].reduce((acc, cur) => (acc + cur), 0)
    }

    static totalGenerationTracked() {
        return testdata.slice(0, today - 1).reduce((acc, cur) => (acc + cur.reduce((a, b) => (a + b), 0)), 0) + this.todayTotal();
    }

    static totalGenerationTrackedFullDays() {
        return testdata.slice(1, today - 1).reduce((acc, cur) => (acc + cur.reduce((a, b) => (a + b), 0)), 0) + this.todayTotal();
    }

    static totalSavings() {
        return this.totalGenerationTracked() * 5;
    }

    static dateToday() {
        return new Date().toLocaleDateString('en-US', {day: 'numeric', month: 'long', year: 'numeric'});
    }

    static systemOnline() {
        const now = new Date(); // Get current time

        // Create Date objects for the start and end times
        const startTime = new Date();
        startTime.setHours(7, 45, 0, 0); // 7:45 AM

        const endTime = new Date();
        endTime.setHours(20, 0, 0, 0); // 8:00 PM

        // Check if the current time is between the start and end times
        return now >= startTime && now <= endTime;
    }

    static plantSize() {
        return 6;
    }

    static specificYield() {
        return this.totalGenerationTrackedFullDays() / (this.plantSize() * (today - 1 + (new Date().getHours() / 24)));
    }

    static temperature() {
        return 31;
    }

    static currentPower() {
        const hour = new Date().getHours();
        return hour >= 5 && hour <= 21 ? this.loadTodayGraph()[hour - 5] : 0;
    }

    static holisticGraph() {
        const ret = {
            labels: [],
            totalGen: [],
            totalCon: [],
            usedGen: [],
            exportedGen: [],
            sy: []
        };
        const data = testdata.slice(0, today - 1);
        data.push(this.loadTodayGraph());
        const date = new Date().getDate() - data.length;
        const month = new Date().getMonth() + 1;
        ret.labels = data.map((_, i) => `${date + i + 1}/${month}`);
        ret.totalGen = data.map(day => day.reduce((acc, cur) => acc + cur, 0));
        ret.totalCon = data.map(day => 0);
        ret.usedGen = ret.totalGen.map(day => day * .8);
        ret.exportedGen = ret.totalGen.map(day => day * .2);
        ret.sy = ret.totalGen.map(day => day / this.plantSize());
        return ret;
    }

    static loadPastToDTotals() {
        // test data 2d array to 1d array totaling all rows columns. i.e. column sum
        return testdata.reduce((acc, cur) => acc.map((v, i) => v + cur[i]), Array(testdata[0].length).fill(0));
    }
}

export const consumptionData =false; 
