<template>
  <div style="padding: 20px; overflow: scroll;">
    <div v-if="!showAutoPurchaseSetup">
      
      <div style="display: flex; justify-content: center">
        <div class="grey-container">
          <div class="button-wrapper">
      <div class="slider" :class="sliderClass"></div>
      <button class="grey-button" @click="toggleSection('buy')" :class="{ active: showsell }">Buy</button>
      <button class="grey-button" @click="toggleSection('sell')" :class="{ active: showbuy }">Sell</button>
    </div>
        </div>
      </div>
      <div class="peer">
        <p>Peer to peer energy buying at competitive prices.</p>
      </div>

      <div v-if="showsell">
        <div class="sell_cards-container">
    <div class="sell_card">
      <div class="sell_card_head">
        <h2>Available</h2>
        <p>50 <span>kWh</span></p>
      </div>
      <img src="../../images/rectangle.png" class="sell_card-image" />
    </div>
    <div class="sell_card">
      <div class="sell_card_head">
        <h2>Produced</h2>
        <p>200 <span>kWh</span></p>
      </div>
      <img src="../../images/rectangle.png" class="sell_card-image" />
    </div>
    <div class="sell_card">
      <div class="sell_card_head">
        <h2>Sold</h2>
        <p>250 <span>kWh</span></p>
      </div>
      <img src="../../images/rectangle.png" class="sell_card-image" />
    </div>
  </div>
       
      </div>
      <div class="card-container">
        <div class="card-header">
          <span class="card-heading">Savings Calculator</span>
          <img src="../../images/buy_down.png" alt="Dropdown" @click="toggleRangeSelector" class="dropdown-icon" style="height: 9px; width: 14px;"/>
        </div>
        <div v-if="showRangeSelector" class="range-selector">
    <p :style="{ color: violetColor }">{{ rangeValue }} kWh <span style="color: #000000;">per day</span></p>
    <input 
      type="range" 
      min="0" 
      max="1000" 
      step="10" 
      v-model="rangeValue" 
      @input="updateVioletFill"
      :style="rangeBackgroundStyle" 
    />
          <div class="text-section">
            <div class="text-buttons">
              <p>Over the past</p>
              <div class="button-group">
                <button @click="setPeriod('1m')">1 Month</button>
                <button @click="setPeriod('6m')">6 Months</button>
                <button @click="setPeriod('1y')">1 Year</button>
              </div>
            </div>
            <hr />
            <div v-if="showsell" class="sell-headings">
              <div class="purchase_card_sell">
              <div>
                <p class="purchase">You have earned </p>
                <p class="save fifty"> ₹ 50</p>
              </div>
              <div>
                <img src="../../images/ruppee2.svg" alt="">
              </div>
            </div>
</div>
<div v-if="!showsell" class="sell-headings">

            <div class="purchase_card">
              <div>
                <p class="purchase">Total purchase of 1500 kWh</p>
                <p class="save">You will save <span>₹12,765</span></p>
              </div>
              <div>
                <img src="../../images/ruppee2.svg" alt="">
              </div>
            </div>
            </div>
            <button class="set_up_auto" @click="showModal = true">Set Up Auto Purchase</button>
            <div v-if="showModal" class="modal-overlay" @click.self="closeModal">
              <div class="modal-content">
                <img src="../../images/modal_ruppe.png" alt="Center Image" class="modal-image" />
                <p class="setup_one">Simplify Your Payment <br>With Autopay</p>
                <p class="setup_two">Allow us to easily invest and save your money without manual payments.</p>
                <p class="setup_three">Activate Auto pay</p>
                <button class="modal-setup-button" @click="showAutoPurchase">Set Up</button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="recommended-container">
        <div class="recommended-left-content">
          <h1>Recommended</h1>
          <div class="info-container">
            <img src="../../images/i.svg" alt="Info Icon" class="info-icon">
            <p>Based on your consumption pattern</p>
          </div>
        </div>

        <div class="recommended-right-content">
          <button class="recommended-action-button">
            <img src="../../images/refresh.svg" alt="">
          </button>
        </div>
      </div>
      <div class="days-dropdown-container">
        <select id="date-select" v-model="selectedDay">
          <option value="today">Today</option>
          <option value="tomorrow">Yesterday</option>
          <option value="month">Month</option>
        </select>
      </div>
      <div class="unit-cards">
        <div class="recommended-units-card" v-for="(card, index) in cards" :key="index">
          <div class="left-unit-card">
            <h1>{{ card.units }} Units</h1>
            <div class="time_date">
              <div style="display: flex; gap: 5px;">
                <img :src="card.dateIcon" alt="Date">
                <p>{{ card.date }}</p>
              </div>
              <div style="display: flex; gap: 5px;">
                <img :src="card.timeIcon" alt="Clock">
                <p>{{ card.time }}</p>
              </div>
            </div>
            <p class="you_save">{{ card.saveMessage }}</p>
          </div>
          <div class="right-unit-card">
            <p>{{ card.price }}</p>
            <p>per unit ₹{{ card.unitPrice }}/-</p>
            <button>Buy</button>
          </div>
        </div>
      </div>
    </div>
    <AutoPurchase v-if="showAutoPurchaseSetup" />
  </div>
</template>


<script>
import AutoPurchase from '../components/AutoPurchase.vue';

export default {
  components: {
    AutoPurchase,
  },
  data() {
    return {
      showModal: false,
      showAutoPurchaseSetup: false,
      selectedDay: 'today',
      showbuy: true,
      showsell: false,
      showRangeSelector: false,
      rangeValue: 0,
      violetColor: '#D9D4FF', 
      cards: [
        {
          units: 100,
          dateIcon: require('../../images/date.svg'),
          date: '6 June',
          timeIcon: require('../../images/clock.svg'),
          time: '03:30 - 5:30PM',
          saveMessage: 'You save ₹200 on this purchase',
          price: '₹ 500.00',
          unitPrice: 5
        },
        {
          units: 150,
          dateIcon: require('../../images/date.svg'),
          date: '7 June',
          timeIcon: require('../../images/clock.svg'),
          time: '01:30 - 3:30PM',
          saveMessage: 'You save ₹300 on this purchase',
          price: '₹ 750.00',
          unitPrice: 5
        },
        {
          units: 200,
          dateIcon: require('../../images/date.svg'),
          date: '8 June',
          timeIcon: require('../../images/clock.svg'),
          time: '02:30 - 4:30PM',
          saveMessage: 'You save ₹400 on this purchase',
          price: '₹ 1000.00',
          unitPrice: 5
        }
      ]
    };
  },
  computed: {
    sliderClass() {
      if (this.showbuy) return 'slide-left';
      if (this.showsell) return 'slide-middle';
      return '';
    },
    rangeBackgroundStyle() {
      const percentage = (this.rangeValue / 1000) * 100;
      return {
        background: `linear-gradient(to right, ${this.violetColor} ${percentage}%, #D9D9D9 ${percentage}%)`,
      };
    }
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    showAutoPurchase() {
      this.showModal = false;
      this.showAutoPurchaseSetup = true;
    },
    toggleSection(section) {
      this.resetMainButtons();
      if (section === 'sell') {
        this.showsell = true;
        this.showbuy = false;
      } else if (section === 'buy') {
        this.showbuy = true;
        this.showsell = false;
      }
    },
    resetMainButtons() {
      this.showsell = false;
      this.showbuy = false;
    },
    toggleRangeSelector() {
      this.showRangeSelector = !this.showRangeSelector;
    },
    setPeriod(period) {
      console.log('Selected period:', period);
    },
    updateVioletFill() {
      this.violetColor = '#453A94'; 
    }
  },
};
</script>


  


  
<style scoped>
.grey-container {
  background-color: #f0f0f0;
  border-radius: 30px;
  margin-top: 20px;
  width: 80%;
}

.button-wrapper {
  position: relative;
  display: flex;
  border-radius: 30px;
  overflow: hidden;
}

.button-wrapper .grey-button {
  flex: 1;
  color: #FFFFFF;
  margin: 10px;
  border: none;
  cursor: pointer;
  font-size: 18px;
  font-family: 'Montserrat', sans-serif;

  text-transform: uppercase;
  border-radius: 30px;
  background-color: transparent;
  transition: background-color 0.3s, color 0.3s;
  z-index: 1;
}

.button-wrapper .grey-button.active {
  color: #000000;
  font-weight: 500;
}

.button-wrapper .slider {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50%;
  border-radius: 30px;
  background: linear-gradient(to right, #050011, #120B41);
  transition: transform 0.3s;
  z-index: 0;
}

.button-wrapper .slider.slide-left {
  transform: translateX(0%);
}

.button-wrapper .slider.slide-middle {
  transform: translateX(100%);
}

.button-wrapper .slider.slide-right {
  transform: translateX(200%);
}

.peer p {
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  text-align: left;
  margin-top: 10px;
  color: #000000;
}

.card-container {
  margin-top: 20px;
  padding: 15px;
  background-color: #F3F1FF;
  border-radius: 15px;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.card-heading {
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  color:#000000;
  
}
.range-selector {
  margin-top: 10px;
}

.range-selector input[type="range"] {
  -webkit-appearance: none; 
  appearance: none;
  width: 100%; 
  height: 5px;
  outline: none; 
  opacity: 0.7; 
  border-radius: 10px;
  transition: opacity .15s ease-in-out; 
}

.range-selector input[type="range"]::-webkit-slider-runnable-track,
.range-selector input[type="range"]::-moz-range-track {
  height: 5px;
}

.range-selector input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px; 
  height: 20px; 
  background: #D9D9D9;
  cursor: pointer; 
  border-radius: 50%; 
  position: relative;
  top: -7.5px; 
}

.range-selector input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px; 
  background: #D9D9D9 !important; 
  cursor: pointer; 
  border-radius: 50%; 
  position: relative;
  top: -7.5px; 
}
.text-section {
  margin-top: 15px;
}

.text-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.text-buttons p {
  margin: 0;
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;

}

.button-group {
  display: flex;
  gap: 10px;
}

.button-group button {
  padding: 5px 10px;
  border: 0.5px solid #AAAAAA;
  border-radius: 20px;
  color: #000000;
  cursor: pointer;
  font-size: 10px;
  font-family: 'Montserrat', sans-serif;

}
.button-group button:active{
  background-color: #00F2B2;
  border: none;
}

hr {
  margin-top: 10px;
  border: 1px solid #ccc;
}

.purchase{
    text-align: left;
    font-size: 12px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    color:#656565;
}
.save{
    text-align: left;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    color:#000000;
}
.save span{
    color:#13B78C;
    font-size: 16px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;

}

.range-selector input[type="range"] {
  -webkit-appearance: none; 
  appearance: none;
  width: 100%; 
  height: 5px;
  background: #ddd; 
  outline: none; 
  opacity: 0.7; 
  border-radius: 10px;
  transition: opacity .15s ease-in-out; 
}

.range-selector input[type="range"]:hover {
  opacity: 1; 
}

.range-selector input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px; 
  height: 20px; 
  background: #453A94 !important; 
  cursor: pointer; 
  border-radius: 50%; 
  position: relative;
  top: -7.5px; 
}

.range-selector input[type="range"]::-moz-range-thumb {
  width: 20px;
  height: 20px; 
  background: #453A94 !important; 
  cursor: pointer; 
  border-radius: 50%; 
  position: relative;
  top: -7.5px; 
}

.range-selector input[type="range"]::-webkit-slider-runnable-track {
  /* background: linear-gradient(to right, violet 0%, violet calc(var(--value) * 1%), #ddd calc(var(--value) * 1%), #ddd 100%); */
  height: 5px;
}

.range-selector input[type="range"]::-moz-range-track {
  background: linear-gradient(to right, violet 0%, violet calc(var(--value) * 1%), #ddd calc(var(--value) * 1%), #ddd 100%);
  height: 5px;
}
.auto_purchase{
    margin-top: 10px;
    display: flex;
    align-items: center;
justify-content: space-between;
  background-color: #F3F1FF;
  border-radius: 15px;
  padding: 15px;
}
.auto_purchase p{
    margin: 0;
    font-size: 14px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
}
.setup{
    background-color: #453A94;
    color:#ffffff;
    border-radius: 40px;
    font-size: 12px;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    padding: 4px 23px;
}

.recommended-left-content h1 {
  text-align: left;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  font-weight: 600;
  margin: 0;
}

.recommended-left-content p {
  text-align: left;
  font-size: 12px;
  font-family: 'Montserrat', sans-serif;
  color: #656565;
  font-weight: 400;
  margin: 0;
}

.recommended-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  max-width: 100%;
  margin-top: 15px;
}

.recommended-left-content {
  display: flex;
  flex-direction: column;
}

.recommended-right-content {
  display: flex;
  align-items: center;
}

.recommended-action-button {
  background-color: #453A94;
width: 28px;
height: 27px;
  border-radius: 5px;

  display: flex;
justify-content: center;

}

.info-container {
  display: flex;
  align-items: center;
  margin-top: 5px;
}

.info-icon {
  margin-right: 10px;
}
#date-select {
    display: flex;
  padding: 5px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid #BDBDBD;
  margin-top: 15px;
}

.unit-cards{
    margin-top: 15px;
}
.recommended-units-card{
    display: flex;
    justify-content: space-between;
    background-color: #F4F4F4;
    padding: 15px 15px;
    border-radius: 15px;
    margin-bottom: 20px;
}
.time_date{
    display: flex;
    align-items: center;
    gap:12px;
    margin-top: 12px;

}
.time_date p{
    margin: 0;
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    color:#000000;

}
.left-unit-card h1{
    font-size: 20px;
    font-family: 'Montserrat', sans-serif;
    color:#000000;
    text-align: left;
    font-weight: 700;
}
.you_save{
    color: #00A86C;
    font-size: 11px;
    font-family: 'Montserrat', sans-serif;
    margin-top: 12px;
    text-align: left;

}
.right-unit-card{
  background: linear-gradient(to bottom, #FFFFFF, #E6E6E6);
  border-radius: 15px;
  padding: 6px 10px;
;
}
.right-unit-card p:nth-child(1){
    font-size: 16px;
    color:#453A94;
    font-weight: 700;
    font-family: 'Montserrat', sans-serif;
    margin: 0;



}
.right-unit-card p:nth-child(2){
    font-size:12px;
    color:#656565;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;


}
.right-unit-card button{
    font-size: 12px;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    background-color: #453A94;
     border-radius: 40px;
     color:#ffffff;
     text-transform: uppercase;
     padding: 4px 30px;
     margin-top: 18px;

}

.purchase_card_sell{
  display: flex;
  justify-content: space-between;
  gap: 30px; 
  border-radius: 15px;
  padding: 24px;
  background: url("../../images/purchase_bg.png") no-repeat center center;
  background-size: cover; 
  width: 100%; 

}
.purchase_card {
  display: flex;
  gap: 30px; 
  border-radius: 15px;
  padding: 24px;
  background: url("../../images/purchase_bg.png") no-repeat center center;
  background-size: cover; 
  width: 100%; 
}

.purchase_card div {
  display: flex;
  flex-direction: column; 
  font-size: 12px;
  color: #ffffff;
}

.purchase, .save {
  margin: 0;
  font-size: 12px;
  color: #ffffff;
  font-family: 'Montserrat', sans-serif;

}
.set_up_auto{
  background: #453A94;
    color: #ffff;
    font-size: 12px;
    padding: 9px 30px;
    border-radius: 7px;
    margin: auto;
    font-family: 'Montserrat', sans-serif;
font-weight: 700;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
}

.modal-content {
  background: white;
  padding: 20px;
  border-radius: 10px;
  text-align: center;
  width: 300px;
}

.modal-image {

 width:102px;
 height: 102px;

  margin-bottom: 20px;
  margin: auto;
}

.setup_one, .setup_three {
  font-size: 18px;
  margin-bottom: 10px;
  margin-top: 20px;
  color: #000000;
  font-weight: 700;
  font-family: 'Montserrat', sans-serif;
}
.setup_two {
  font-size: 12px;
  margin-bottom: 10px;
  margin-top: 10px;

  color: #ADADAD;
  font-family: 'Montserrat', sans-serif;
}

.modal-setup-button {
  background: #453A94;
    color: #ffffff;
    padding: 7px;
    border: none;
    width: 44%;
    border-radius: 5px;
    font-family: 'Montserrat', sans-serif;
    font-size: 12px;
    margin: auto;
}
.sell_cards-container {
  display: flex;
  justify-content: space-between;
  gap: 10px;
}

.sell_card {
  position: relative;
  background: #fff;
  border-radius: 15px;
  flex: 1;
  background: linear-gradient(to right, #050011, #120B41);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 12px; 

  .sell_card-image {
    position: absolute;
    top: 0; 
    right: 1px; 
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 12px;
  }

  .sell_card_head {
    h2 {
      font-size: 12px;
      font-family: 'Montserrat';
      color: #00F2B2;
      text-align: left;
      margin: 0; 
      padding-top: 3px; 
    }
    p {
      font-size: 20px;
      font-family: 'Montserrat';
      text-align: left;
      color: #ffffff;
      margin: 0; 
      span {
        font-size: 10px;
        font-family: 'Montserrat';
        text-align: left;
        color: #ffffff;
      }
    }
  }
}
.fifty{
  font-size: 24px;
      font-family: 'Montserrat';
      color: #ffffff;
}

</style>


